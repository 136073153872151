<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_6b1945319eb18d5f')}}</h3></div> -->
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_e69eafd9946571ed')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="toCreate()" size="small" icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
						<!-- </li> -->
						<!-- <li v-if="10==filterData.status"> -->
							<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out" v-if="10==filterData.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
							
						<!-- </li> -->
					<!-- </ul> -->
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							<!-- {{ item.label }} -->
							{{i18nFormatter(item.value)}}
						</span>
						<span class="btn-badge"
							v-if="'10' == item.value && !!countData.commit && '0' != countData.commit">{{ countData.commit }}</span>
						<span class="btn-badge"
							v-else-if="'60' == item.value && !!countData.pling && '0' != countData.pling">{{ countData.pling }}</span>
						<span class="btn-badge"
							v-else-if="'70' == item.value && !!countData.waitOut && '0' != countData.waitOut">{{ countData.waitOut }}</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="toCreate()" size="small" icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
					</li>
					<li v-if="10==filterData.status">
						<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
						
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
					</li>
					<li>
						<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker size="small" v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>
					
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'">
			<div class="drawerFilterCon">
				<ul class="drawerFilterList">
					<li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" size="medium" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_66f0e38b9285894b')}}</span>
						<el-radio-group v-model="filterData.wh_car_tms_type" size="medium" @change="initData()">
							<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
							<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
						</el-radio-group>
					</li>
					<li class="filterBtnCon">
						<el-button icon="el-icon-search" size="medium" type="primary" @click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
					</li>
				</ul>
			</div>
		</el-drawer> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange">
				<el-table-column type="selection" fixed="left" align="center" width="55">
				</el-table-column>
				
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<!-- <el-table-column prop="inWhCode" :label="$t('i18nn_b6cb663839a53fd9')">
				</el-table-column> -->
				
				<el-table-column prop="outWhCode" :label="$t('i18nn_b1131a0cc3945b5f')" width="150">
					<template slot-scope="scope">
						<div>
							<el-link v-if="scope.row.outWhCode" type="primary" size="mini" icon="" @click="showDetList($event, scope.row)">
								{{ scope.row.outWhCode }}
							</el-link>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="inWhCodes" :label="$t('i18nn_35ec768075eb8f4e')" width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.extentAttrObj">
							<div v-for="(item,index) in scope.row.extentAttrObj.inWhCodes" :key="index">
								<!-- {{item}} -->
								<el-link style="font-size: 12px;" v-if="item" type="primary" size="mini" icon="" @click="toTraInWh($event, item)">
									{{ item }}
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="recordList" :label="''" min-width="150">
					<template slot="header">
						<div>
							<span>{{$t('i18nn_fa4d50bc26e39b26')}}</span>&nbsp;*&nbsp;
							<span>{{$t('i18nn_bdc361ba04506136')}}</span>
						</div>
					</template>
					<template slot-scope="scope">
						<div v-if="scope.row.recordList">
							<div v-for="(item,index) in scope.row.recordList" :key="index" v-if="index<=4">
								<span>{{ item.ctnMark }}</span>&nbsp;*&nbsp;
								<strong>{{ item.trCtnCount }}</strong>
							</div>
							<div v-if="scope.row.recordList.length>4">
								<el-link type="primary" size="mini" icon=""
									@click="showDetList($event, scope.row)">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>{{scope.row.recordList.length}}<span>{{$t('i18nn_3e48b039f79c39a0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_fd6620afef3fab05')"></el-table-column>
				<!-- <el-table-column prop="inWhTypeName" :label="$t('i18nn_643e08ba53f72f39')"></el-table-column> -->
				
				<el-table-column prop="isFbaName">
					<template slot="header">
						<div>
							<span>{{$t('i18nn_e1d76a34da86da92')}}</span>FBA
						</div>
					</template>
					<template slot-scope="scope">
						<!-- {{ scope.row.trOpTypeName }} -->
						<!-- {{ $Utils.i18nKeyText(scope.row,'isFbaName') }} -->
						<div>
							<el-tag type="success" v-if="1==scope.row.isFba">{{ $Utils.i18nKeyText(scope.row,'isFbaName') }}</el-tag>
							<el-tag type="info" v-else-if="0==scope.row.isFba">{{ $Utils.i18nKeyText(scope.row,'isFbaName') }}</el-tag>
							<div v-else>
								{{ $Utils.i18nKeyText(scope.row,'isFbaName') }}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="fbaAddrCode" :label="$t('i18nn_579f43c8c657bbbc')">
					<template slot="header">
						<div>
							<span>{{$t('i18nn_81a7c94b5c510304')}}</span><span>{{$t('i18nn_5acbec83efb27445')}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="relationNo" :label="'FBA申请号/物流号/转运单号'" width="180">
					<template slot="header">
						<div>
							<span>FBA<span>{{$t('i18nn_1272ee58ec6ee1ad')}}</span>/<span>{{$t('i18nn_7110aa855f2767a1')}}</span>/<span>{{$t('i18nn_559fed47b0e95407')}}</span></span>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="isShowSkuName" :label="$t('i18nn_696eca4149742942')"></el-table-column> -->

				<!-- <el-table-column prop="addedService" :label="$t('i18nn_46b944e39511234b')"></el-table-column> -->
				
				<el-table-column prop="trType" :label="$t('i18nn_e98a6f2582037336')">
					<template slot-scope="scope">
						<span>
							<!-- {{ scope.row.trTypeName }} -->
							{{ $Utils.i18nKeyText(scope.row,'trTypeName') }}
						</span>
						<div v-if="'10'==scope.row.trType && scope.row.containerSize">
							<el-tag plain>{{scope.row.containerSize}}</el-tag>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="addr" :label="$t('i18nn_f2896032570c4d41')" min-width="200">
					<template slot-scope="scope">
						<div>
							<div>
								<span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>：{{ scope.row.addr }},{{ scope.row.city }},{{ scope.row.state }},{{ scope.row.country }}
							</div>
							<div>
								<span>{{$t('i18nn_5a9fb0915ecea987')}}</span>：{{ scope.row.phone }}
							</div>
							<div>
								<span>{{$t('i18nn_c4913ab43009b365')}}</span>：{{ scope.row.postalCode }}
							</div>
						</div>
					</template>
				</el-table-column>
				
				<!-- <el-table-column prop="addr" :label="$t('i18nn_8758fd50c87d6c9c')" width="200">
					<template slot-scope="scope">
						{{ scope.row.addr }},{{ scope.row.city }},{{ scope.row.state }},{{ scope.row.country }}
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="phone" :label="$t('i18nn_5a9fb0915ecea987')"></el-table-column> -->
				<!-- <el-table-column prop="city" :label="$t('i18nn_e05b5d049b64b040')"></el-table-column>
				<el-table-column prop="state" :label="'省/州'"></el-table-column>
				<el-table-column prop="country" :label="$t('i18nn_b166e4e8fe9513fa')"></el-table-column> -->
				<!-- <el-table-column prop="postalCode" :label="$t('i18nn_c4913ab43009b365')"></el-table-column> -->

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')">
				</el-table-column>
				<el-table-column prop="planOutDate" :label="$t('i18nn_c301b10948889cb5')">
				</el-table-column> -->
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_0ae081c3b8c4d4a1')}}</span>:{{scope.row.commitDate}}
								</li>
								<li>
									<span>{{$t('i18nn_c301b10948889cb5')}}</span>:{{scope.row.planOutDate}}
								</li>
								<!-- <li>
									备货完成日期:{{scope.row.waitOutWhDate}}
								</li>
								<li>
									出库完成日期:{{scope.row.outWhDate}}
								</li> -->
							</ul>
						</div>
					</template>
				</el-table-column>
				
				<!-- <el-table-column prop="inWhDate" :label="$t('i18nn_4647ba3fc60f0151')"></el-table-column> -->

				<!-- <el-table-column prop="isLiveUploadName" :label="$t('i18nn_e137950d1897817e')"></el-table-column> -->

				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

				<!-- <el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')">
					<template slot-scope="scope">
						<div @click="openUploadFile($event, scope.row)">
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column> -->


				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'60' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'70' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="" v-else-if="'80' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" effect="plain" v-else-if="'99' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="280px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>


							<div style="margin-bottom: 5px;">
								<!-- <el-button @click="showDetList($event, scope.row)" type="primary" size="mini"
									icon="el-icon-view">{{$t('FormMsg.details_View')}}</el-button> -->
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
									
								
									
								<el-button @click="openQARecList($event, scope.row)"  type="primary" plain size="mini" icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div>
							
							<div style="margin-bottom: 5px;">
								<el-button @click="updataDestination($event, scope.row)" type="primary" size="mini"
									icon="el-icon-edit">{{$t('i18nn_f4af8c42d7478c6f')}}</el-button>
							</div>

							<div style="margin-bottom: 5px;" v-if="'0' == scope.row.status">
								<el-button @click="commitAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-sell">{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
							</div>
							<div style="margin-bottom: 5px;">
								<el-button @click="downLoadBolFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-download"><span>{{$t('i18nn_245883c80f181c4a')}}</span>BOL</el-button>
							</div>
							<div style="margin-bottom: 5px;" v-if="10==scope.row.status">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger" size="mini" icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
							</div>
							
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 自提单,修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="0" :title="editId?$t('i18nn_ed74196efa90cded'):$t('i18nn_004bc5eedbcc75e0')"
			:visible.sync="dialogFormVisible" custom-class="myFullDialog4">
			<div>
				<TransportManageEdit :editId="editId" :openTime="openTimeEditId" @submitSuccess="editSuccess">
				</TransportManageEdit>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
			</div>
		</el-dialog> -->

		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogShowDet" width="1200px" top="0">
			<div style="">
				<TransportManageDetList :sendId="sendId"></TransportManageDetList>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button>
			</div>
		</el-dialog> -->
		<!-- 售后 -->
		<QAModelRecList
			:openTime="QAMoRecListOpenTime"
			:type="'60'"
			:relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo"
			:whNo="QAMoRecListWhNo"
			@success="QAMoRecListSuccess"
		></QAModelRecList>
		
		<!--  更新目的地 -->
		<TransportUpdataDestination ref="TransportUpdataDestination" :openTime="TransportUpdataAdrOpenTime" :row="TransportUpdataAdrRow" @success="successSubmitDestData">
		</TransportUpdataDestination>

		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import {
		TransportOutWh_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';
	
	import {
		downloadPdfBuffer
	} from '@/utils/pdfUtils.js';
	
	// import TransportManageEdit from '@/components/StorageCenter/Transport/TransportManageEdit.vue';

	// import TransportManageDetList from '@/components/StorageCenter/Transport/TransportManageDetList.vue';
	import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	
	import TransportUpdataDestination from '@/components/StorageCenter/Transport/TransportUpdataDestination.vue';
	
	import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';
	
	export default {
		// props: {
		//   // mobile:"",
		//   isSel: {
		//     default: function() {
		//       return false;
		//     },
		//     type: Boolean
		//   },
		//   status: {
		//     default: function() {
		//       return '';
		//     },
		//     type: String
		//   }
		// },
		components: {
			// WSkuInfo,
			// TransportManageEdit,
			// TransportManageDetList,
			TransportUpdataDestination,
			whFileUpload,
			SelAgentUser,
			whNoSelect,
			QAModelRecList
		},
		data() {
			return {
				dialogShowDet: false,
				sendId: null,

				fileRelationId: '',
				FileUploadOpenTime: '',

				drawerFilter: false,
				
				//售后
				QAMoRecListOpenTime:"",
				QAMoRecListRelationId:"",
				QAMoRecListRelationNo:"",
				// QAMoRecListUserId:"",
				QAMoRecListWhNo:"",
				
				TransportUpdataAdrOpenTime:'',
				TransportUpdataAdrRow:{},

				pickerOptions: this.$PickerDate.pickerOptions_1(),

				multipleSelection:[],
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// editId: "",
				// openTimeEditId: '',
				loading_count: false,
				countData: {},

				loading: false,

				loading_det: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_car_tms_type: [],
					// wh_no:[],
					statusList: TransportOutWh_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					workNo: '',
					status: '',
					packingNo: '',
					keyword: "",
					commitDateArr: [],
					whNo: '',
					wh_car_tms_type: ''
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},
		// watch:{
		// 	'$route.query': function(newVal, oldVal) {
		//     console.log('watchKey');
		//     // if (newVal) {
		//     // console.log('watch openDateTime HyUpLoadfile.vue');
		// 		if(this.$route.query.workNo){
		// 			this.filterData.workNo = this.$route.query.workNo;
		// 			this.initData();
		// 		}

		//     // }
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			// if (this.$route.query.workNo) {
			// 	this.filterData.workNo = this.$route.query.workNo;
			// 	// this.initData();
			// }
			if (this.$route.query.state) {
				this.filterData.status = this.$route.query.state;
				// this.initData();
			}
			if (this.$route.query && this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type'],
				(data)=>{
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
			});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				this.getCountData();
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = true;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			
			//选择行
			handleCurrentChange(row, event, column) {
			  // this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
			  console.log(val);
			  this.multipleSelection = val;
			},
			
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			toCreate() {
				// this.$router.push({
				// 	name: "createWorkOrder",
				// 	query: {
				// 		orderType: '30'
				// 	}
				// })
				this.openDioalog();
			},
			//打开新增编辑，弹窗
			openDioalog() {
				//   // console.log(formParm);
				// this.dialogFormVisible = true;
				// this.editId = null;
				// this.openTimeEditId = new Date().getTime();

				this.$router.push({
					name: "TransportOutWhEdit"
				})
			},
			// //打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row);

				// this.dialogFormVisible = true;
				// this.editId = row.id;
				// this.openTimeEditId = new Date().getTime();
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "TransportOutWhEdit",
					query: {
						editId: row.id
					}
				})
			},

			//查看详情
			showDetList($event, row) {
				$event.stopPropagation();
				// this.dialogShowDet = true;
				// this.sendId = row.id;
				this.$router.push({
					name: "TransportOutWhDetList",
					query: {
						id: row.id
					}
				});
			},
			
			//跳转到转运入库
			toTraInWh($event, code) {
				$event.stopPropagation();
				// this.dialogShowDet = true;
				// this.sendId = row.id;
				this.$router.push({
					name: "TransportInWh",
					query: {
						code: code
					}
				});
			},
			
			//提交
			commitAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t('tips.confirmSubmit'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {

						this.postData(this.$urlConfig.WhTransferOutCommit, {
							id: row.id
						},'',()=>{});
					})
					.catch(() => {});
			},
			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.delDataAction(event, row);
						this.postData(this.$urlConfig.WhTransferOutDel + '/' + row.id, {}, 'delete',()=>{});
					})
					.catch(() => {});
			},
			//撤销
			commitCancelAction(event, row) {
			  event.stopPropagation();
			
			  let parm = [];
			
			    if (row) {
			  //     //单条
			      parm = [row.id];
			    } else {
			      //多条
			      let dataList = this.multipleSelection;
			      if (dataList.length < 1) {
			        this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
			        return;
			      }
			      // if (dataList.findIndex(item=> '10'!=item.status)>-1) {
			      //   this.$message.warning(this.$t('tips.long_InWarehouse_1'));
			      //   return;
			      // }
			
			      let dataListParm = dataList.map(v => v.id);
			      parm = dataListParm;
			    }
			
			  this.$confirm(this.$t('Storage.DropShipping.sure_submit_1')+parm.length+this.$t('Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
			    // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			    // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			    type: 'warning'
			  })
			    .then(() => {
						// {"ids":[11111111111111]}
			      this.postData(this.$urlConfig.WhTransferOutRemove, {"ids":parm},'',()=>{});
			    })
			    .catch(() => {});
			},
			
			//售后
			openQARecList(event, row){
				event.stopPropagation();
				this.QAMoRecListRelationId = row.id;
				this.QAMoRecListRelationNo = row.outWhCode;
				// this.QAMoRecListUserId = row.userId;
				this.QAMoRecListWhNo = row.whNo;
				this.QAMoRecListOpenTime = new Date().getTime();
			},
			QAMoRecListSuccess() {
				this.initData();
			},
			

			//提交信息
			postData(url, formData, type,callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.initData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
						callback();
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			handleClick(tab, event) {
				console.log(tab, event);
				// this.form.sendRecordDtoList = [];
			},

			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			
			//统计状态数量
			getCountData() {
				this.loading_count = true;
				this.$http
					.get(this.$urlConfig.WhTransferOutStatusCount, {})
					.then(({
						data
					}) => {
						
						this.loading_count = false;
						if (200 == data.code) {
							//表格显示数据
							this.countData = data.data;
						} else {
							this.$message.warning(data.msg ? data.msg : '状态统计，查询失败');
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('状态统计，查询失败！');
						this.loading_count = false;
					});
			},
			
			//分页的筛选项数据
			pageFilterData() {

				let commitDateArr = this.filterData.commitDateArr;
				let startCommitDate = '';
				let endCommitDate = '';
				if (commitDateArr && commitDateArr.length == 2) {
					startCommitDate = commitDateArr[0];
					endCommitDate = commitDateArr[1];
				}

				return {
					workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					startCommitDate: startCommitDate ? startCommitDate : null,
					endCommitDate: endCommitDate ? endCommitDate : null,

					carTmsType: this.filterData.wh_car_tms_type ? this.filterData.wh_car_tms_type : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferOutPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//打开附件上传
			openUploadFile(event, row) {
				event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},
			
			//更新目的地
			updataDestination(event, row){
				event.stopPropagation();
				this.TransportUpdataAdrOpenTime = new Date().getTime();
				this.TransportUpdataAdrRow = row;
			},
			
			successSubmitDestData(fbaAddrCode) {
				let formData = {
					"id": this.TransportUpdataAdrRow.id,
					"fbaAddrCode": fbaAddrCode
				};
				console.log('formData', formData);
				this.postData(this.$urlConfig.WhTransferUpdataAddress, formData,'',()=>{
					this.$nextTick(()=>{
						this.$refs.TransportUpdataDestination.close();
					})
				});
			},
			
			//下载BOL
			downLoadBolFile(event, row){
				event.stopPropagation();
				this.loading_load = true;
				downloadPdfBuffer(this.$urlConfig.WhTransferOutDownloadBol+"/"+row.id,`BOL_${row.outWhCode}_${new Date().getTime()}`);
				this.loading_load = false;
			},
			
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {

	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
